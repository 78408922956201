<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="listas" class="p-datatable-sm" v-model:selection="selected" dataKey="id_cuenta" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['no_cuenta', 'emisor', 'descripcion']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} marcas" :loading="loadingGrid">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Cuentas</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="no_cuenta" header="No. Cuenta" :sortable="true"/>
                    <Column field="id_moneda" header="Moneda" :sortable="true"/>
                    <Column field="emisor" header="Emisor" :sortable="true"/> 
                    <Column field="cuenta_tipo" header="Tipo" :sortable="true"/>
                    <Column field="descripcion" header="Descripcion" :sortable="true"/>                           
                    <Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="editDialog" :style="{width: '450px'}" header="Rubro" :modal="true">
					<div class="confirmation-content">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="tipo" style="font-weight: bold;">Tipo</label>
        						<Dropdown v-model="lista.id_cuenta_tipo" :options="lkpTipos" optionLabel="cuenta_tipo" optionValue="id_cuenta_tipo"/>
							</div>      							
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="emisor" style="font-weight: bold;">Emisor</label>
        						<Dropdown v-model="lista.id_emisor" :options="lkpEmisores" optionLabel="emisor" optionValue="id_emisor"/>
							</div>      							
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="moneda" style="font-weight: bold;">Moneda</label>
        						<Dropdown v-model="lista.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda"/>
							</div>      							
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="no_cuenta" style="font-weight: bold;">No.Cuenta</label><span style="color: red;">*</span>
								<InputText id="no_cuenta" v-model="lista.no_cuenta" :maxlength="40" required="true" autofocus />
							</div>
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="descripcion" style="font-weight: bold;">Descripción</label><span style="color: red;">*</span>
								<InputText id="descripcion" v-model="lista.descripcion" :maxlength="100" required="true" />
							</div>    							
						</div>  
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog=false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" :disabled="!puedeActualizarRubro"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="enUsoDialog" :style="{width: '450px'}" header="En Uso" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">No es posible remover la cuenta <b>[{{ lista.no_cuenta }}]</b> por que tiene registros asociados!</span>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="enUsoDialog = false"/>						
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">Esta seguro que desea eliminar la cuenta? <br><b>[{{ lista.no_cuenta }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="eliminar" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import BanService from '../../service/ban.service';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {FilterMatchMode} from 'primevue/api';
// import Checkbox from 'primevue/checkbox';

export default {
	setup() {
		return { v$: useVuelidate() }
	},		
	components:
	{
		Loading
	},
	data() {
		return {
			listas: null,
			listaDialog: false,		
			editingCellRows: {},
			editDialog: false,	
			deleteDialog: false,
			enUsoDialog: false,
			lkpTipos: null,
			lkpEmisores: this.$store.state.auth.currentAppCtx.Emisores,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lista: {
                no_cuenta: '',
                emisor: '',
				cuenta_tipo: '',
				descripcion: '',
			},
			selected: null,
			loading: false,
			loadingGrid: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	validations() {
		return {
			lista: { 
				no_cuenta: { required },		
				descripcion: { required },
			}
		}
	},
	computed: {
		puedeActualizarRubro() {
			return !this.v$.lista.$invalid;
		}
	},
	mounted() {
        this.cargarTiposCuenta();
		this.cargarInfo();
	},
	methods: {
		async cargarTiposCuenta() {
			this.loading = true;
            try {
                var data = await BanService.getCuentasTipos(this.$store.state.auth.currentAppCtx);
                this.lkpTipos = data;

                this.loading = false;	
            } catch (err) {
				this.loading = false;
                this.lkpTipos = null;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }
		},
		async cargarInfo(){
			this.loading = true;
            try {
                var data = await BanService.getCuentas(this.$store.state.auth.currentAppCtx);
                this.listas = data;

                this.loading = false;	
            } catch (err) {
				this.loading = false;
                this.listas = null;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }
		},
        formatCurrency(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},				
		getEmisorXId(id) {
			var emisor = this.lkpEmisores.find((e)=> e.id_emisor == id)
			return emisor;
		},
		getCuentaTipoXId(id) {
			var tipo = this.lkpTipos.find((e)=> e.id_cuenta_tipo == id)
			return tipo;
		},
		async guardarRec(){			
			this.loading = true;	
			try{
                var data = await BanService.registrarCuenta(this.$store.state.auth.currentAppCtx, this.lista);
				
				var emisorSel = this.getEmisorXId(this.lista.id_emisor);
				var tipoSel = this.getCuentaTipoXId(this.lista.id_cuenta_tipo);
				
				if(emisorSel) {
					this.lista.emisor = emisorSel.emisor;				
				}

				if(tipoSel) {
					this.lista.cuenta_tipo = tipoSel.cuenta_tipo;				
				}

				if(this.lista.id_cuenta == '') {					
					this.lista.id_cuenta = data.id_cuenta;
					this.listas.push(this.lista);
				} else {
					for (var i =0; i < this.listas.length; i++) {
						if (this.listas[i].id_cuenta == this.lista.id_cuenta) {
							this.listas[i] = JSON.parse(JSON.stringify(this.lista));							
							break;
						}
					}	  					
				}
				this.lista = {};

                this.editDialog = false;	
				this.loading = false;	
			} catch (err) {
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
				this.submitted = true;
				this.editDialog = false;
				this.loading = false;
			}
		},
		openNew() {
			this.lista = {
				id_cuenta: '',
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				id_cuenta_tipo: this.lkpTipos[0].id_cuenta_tipo,
				id_emisor: this.lkpEmisores[0].id_emisor,
				id_moneda: this.lkpMonedas[0].id_moneda,
				emisor: '',
				cuenta_tipo: '',
                no_cuenta: '',
                descripcion: '',
				permite_sobregiro: 'N',
				sobregiro: 0,				
				activa: 'S'		
			};
			this.submitted = false;
			this.editDialog = true;
		},
		edit(registro) {
			this.lista = registro;
			this.submitted = false;
			this.editDialog = true;
		},
		async confirmDelete(registro) {
			this.loading = true;
			try{
				this.lista = registro;
				var data = await BanService.cuentaEnUso(this.$store.state.auth.currentAppCtx, this.lista);
				this.loading = false;
				if(data.en_uso) {
					this.enUsoDialog = true;
				} else {
					this.deleteDialog = true;
				}
			} catch (err) {
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
		}, 
		async eliminar(registro) {
			this.loading = true;
			try {
                var data = await BanService.removerCuenta(this.$store.state.auth.currentAppCtx, this.lista);

				this.listas = this.listas.filter(val => val.id_cuenta !== this.lista.id_cuenta);
				this.deleteDialog = false;
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'La cuenta ha sido eliminada', life: 3000});
			} catch(err) {
				this.deleteDialog = false;
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			
		},
		confirmDeleteSelected() {
			this.deleteDialog = true;
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
